export const activityTypes = [
    { slug: 'defecto', label: 'Por defecto', value: 0, exclude: true },
    { slug: 'equipo', label: 'Equipo', value: 1 },
    { slug: 'jugador', label: 'Jugador', value: 2 },
    { slug: 'noticia', label: 'Noticia', value: 3 },
    { slug: 'info', label: 'Info', value: 4 },
    { slug: 'entrenamiento', label: 'Entrenamiento', value: 5 },
    { slug: 'gimnasio', label: 'Gym', value: 6, exclude: true },
    { slug: 'verificado', label: 'Verificado', value: 7, exclude: true },
    { slug: 'recomendacion', label: 'Recomendacion', value: 8 },
    { slug: 'alineaciones', label: 'Alineaciones', value: 9, exclude: true },
    { slug: 'mercado', label: 'Mercado', value: 10 },
    { slug: 'rueda-de-prensa', label: 'Rueda de prensa', value: 11 },
    { slug: 'analisis-de-mercado', label: 'Analisis de Mercado', value: 12 },
    { slug: 'analisis-post-partido', label: 'Analisis Post Partido', value: 13 },
    { slug: 'consejos-premium', label: 'Consejo Premium', value: 14 },
];

export const ACTIVITY_TYPE = {
    DEFAULT: 0,
    TEAM: 1,
    PLAYER: 2,
    NOTICIA: 3,
    INFO: 4,
    ENTRENAMIENTO: 5,
    GYM: 6,
    VERIFIED: 7,
    RECOMENDACION: 8,
    ALINEACIONES: 9,
    MERCADO: 10,
    RDP: 11,
    ANALISIS_MERCADO: 12,
    ANALISIS_POST_PARTIDO: 13,
    CONSEJO_PREMIUM: 14,
};

export const getActivitySlug = (type) => {
    return activityTypes.find((activity) => activity.value === type).slug;
};

export const getActivityType = (slug) => {
    return activityTypes.find((activity) => activity.slug === slug).value;
};

export const getActivityLabel = (type) => {
    return activityTypes.find((activity) => activity.value === type).label;
};
