import React, { useState, useEffect } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button,
    Typography,
    Collapse,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TextField,
    Box,
} from '@mui/material';
import parse from 'html-react-parser';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { LEAGUES } from '@/constants/leagues';
import { PLAYER_STATUS } from '@/constants/player-constants';
import { Rutas } from '@/constants/rutas';
import { ACTIVITY_TYPE, activityTypes } from '@/data/activities';
import { obtenerEquipos } from '@/data/equipos';
import { getAuthorLogo } from '@/helpers/author-helper';
import { formatDateToDDMMYYYY, getTimeAgo } from '@/helpers/datetime-helper';
import { getMasterPhotoById, getTeamImageById } from '@/helpers/images-helper';
import { getPlayerIcon, replaceDiacritis } from '@/helpers/players-helper';

import CustomImageComponent from '../shared/custom/custom-image';
import CalendarIcon from '../shared/icons/calendar-icon';
import PremiumIcon from '../shared/icons/subscriptions/premium';
import LazyLoadWrapper from '../shared/wrappers/lazy-load-wrapper';

import AlineacionesActivityIcon from './icons/alineaciones-activity-icon';
import EntrenamientoGymIcon from './icons/entrenamiento-gym-icon';
import ActivityInfoIcon from './icons/info-icon';
import MercadoActivityIcon from './icons/mercado-activity-icon';
import NoticiaIcon from './icons/noticia-icon';
import RdpIcon from './icons/rdp-icon';
import RecomendacionIcon from './icons/recomendacion-icon';
import VerifiedIcon from './icons/verified-icon';

export const getIcon = (activity) => {
    const size = 35;
    switch (activity?.type) {
        case ACTIVITY_TYPE.TEAM:
            if (activity?.team) {
                return (
                    <CustomImageComponent
                        src={getTeamImageById(activity?.team, size * 1.5)}
                        alt="equipo"
                        width={size}
                        height={size}
                    />
                );
            } else {
                return (
                    <CustomImageComponent
                        src={getTeamImageById(0, size * 1.5)}
                        alt="equipo"
                        width={size}
                        height={size}
                    />
                );
            }
        case ACTIVITY_TYPE.PLAYER:
            return (
                <CustomImageComponent
                    src={getMasterPhotoById(activity?.player?.id ?? 0, size * 1.5)}
                    alt={activity?.player?.name ?? 'jugador'}
                    width={size}
                    height={size}
                    style={{ borderRadius: '50%', background: '#22272F' }}
                />
            );
        case ACTIVITY_TYPE.INFO:
            return <ActivityInfoIcon width={size} height={size} />;
        case ACTIVITY_TYPE.NOTICIA:
            return <NoticiaIcon width={size} height={size} />;
        case ACTIVITY_TYPE.ENTRENAMIENTO:
            return <EntrenamientoGymIcon width={size} height={size} />;
        case ACTIVITY_TYPE.GYM:
            return <EntrenamientoGymIcon width={size} height={size} />;
        case ACTIVITY_TYPE.VERIFIED:
            return <VerifiedIcon width={size} height={size} />;
        case ACTIVITY_TYPE.RECOMENDACION:
            return <RecomendacionIcon width={size} height={size} />;
        case ACTIVITY_TYPE.ALINEACIONES:
            return <AlineacionesActivityIcon width={size} height={size} />;
        case ACTIVITY_TYPE.MERCADO:
            return <MercadoActivityIcon width={size} height={size} style={{ marginRight: '5px' }} />;
        case ACTIVITY_TYPE.RDP:
            return <RdpIcon width={size} height={size} />;
        case ACTIVITY_TYPE.ANALISIS_MERCADO:
        case ACTIVITY_TYPE.ANALISIS_POST_PARTIDO:
        case ACTIVITY_TYPE.CONSEJO_PREMIUM:
            return <PremiumIcon width={size} height={size} />;
        default:
            return null;
    }
};

const HomeActivities = ({
    isPage,
    style,
    customActivities,
    customTake,
    loadMoreActividades,
    fetchActivities,
    showHeader = true,
    showFilters = false,
}) => {
    const [actividades, setActividades] = useState(customActivities);
    const [groupedActivities, setGroupedActivities] = useState(null);
    const router = useRouter();

    const [expandedActivityId, setExpandedActivityId] = useState(null);
    const [selectedType, setSelectedType] = useState('ALL');
    const [selectedTeam, setSelectedTeam] = useState('ALL');
    const [searchKeyword, setSearchKeyword] = useState('');

    const toggleActivity = (id) => {
        setExpandedActivityId((prevId) => (prevId === id ? null : id));
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    const handleTeamChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    const handleSearchKeywordChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const buildGroupedActivities = (actividades) => {
        const newActivities = actividades
            ?.filter((_, index) => !customTake || index < customTake)
            ?.reduce((acc, activity) => {
                const date = new Date(activity.created).toDateString();
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(activity);
                return acc;
            }, {});
        setGroupedActivities(newActivities);
    };

    useEffect(() => {
        if (customActivities?.length > 0) {
            setActividades(customActivities);
            buildGroupedActivities(customActivities);
        }
    }, [customActivities]);

    useEffect(() => {
        const activitiesLoaded = customActivities?.length > 0;
        if (fetchActivities && !activitiesLoaded) {
            fetchActivities();
        }
    }, [fetchActivities]);

    const getActivityContent = (content) => {
        if (!content) return null;

        const hasHtmlTags = /<[^>]+>/g.test(content);

        return hasHtmlTags ? parse(content) : content;
    };

    const convertTitle = (title) => {
        // Reemplazar entidades HTML por sus caracteres correspondientes
        let convertedTitle = title.replace(/&quot;/g, '"').replace(/&#039;/g, "'");

        // Definir los reemplazos específicos en orden de prioridad
        const replacements = [
            { regex: /\bDisponible\b/gi, icon: getPlayerIcon(PLAYER_STATUS.OK, 1) },
            { regex: /\bSancionado\b/gi, icon: getPlayerIcon(PLAYER_STATUS.SANCTIONED, 1) },
            { regex: /\bDuda leve\b/gi, icon: getPlayerIcon(PLAYER_STATUS.DOUBTFUL, 1) },
            { regex: /\bDuda\b/gi, icon: getPlayerIcon(PLAYER_STATUS.DOUBTFUL, 2) },
            { regex: /\bExtradeportivo\b/gi, icon: getPlayerIcon(PLAYER_STATUS.INJURED, 3) },
            { regex: /\bLesionado de larga duración\b/gi, icon: getPlayerIcon(PLAYER_STATUS.INJURED, 2) },
            { regex: /\bLesionado\b/gi, icon: getPlayerIcon(PLAYER_STATUS.INJURED, 1) },
        ];

        // Dividir el texto en fragmentos y reemplazar con componentes React
        const parts = [convertedTitle];

        replacements.forEach(({ regex, icon }) => {
            const newParts = [];
            parts.forEach((part, index) => {
                if (typeof part === 'string') {
                    const splitParts = part.split(regex);
                    splitParts.forEach((splitPart, splitIndex) => {
                        newParts.push(splitPart);
                        if (splitIndex < splitParts.length - 1) {
                            newParts.push(
                                React.cloneElement(icon, {
                                    key: `${index}-${splitIndex}`,
                                    style: { marginLeft: '4px', marginRight: '4px' },
                                })
                            );
                        }
                    });
                } else {
                    newParts.push(part); // Mantener componentes ya reemplazados
                }
            });
            parts.splice(0, parts.length, ...newParts); // Actualizar el array original
        });

        return parts;
    };

    const getFilteredActivities = () => {
        if (!groupedActivities) return null;

        return Object.entries(groupedActivities).map(([date, activities]) => {
            let filteredActivities = activities;

            if (selectedType !== 'ALL') {
                filteredActivities = filteredActivities.filter((activity) => activity.type === selectedType);
            }

            if (selectedTeam !== 'ALL') {
                filteredActivities = filteredActivities.filter((activity) => activity.team == selectedTeam);
            }

            if (searchKeyword !== '') {
                filteredActivities = filteredActivities.filter((activity) =>
                    replaceDiacritis(activity.title).includes(replaceDiacritis(searchKeyword))
                );
            }

            return (
                <React.Fragment key={date}>
                    <div
                        className="home-activities__date"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Box style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '12px' }}>
                            <CalendarIcon iconWidth={15} iconHeight={15} color={'#000'} />
                            {formatDateToDDMMYYYY(date)}
                        </Box>
                        <Typography fontSize="12px">{filteredActivities.length} actividades</Typography>
                    </div>
                    {filteredActivities.map((activity) => {
                        return (
                            <LazyLoadWrapper key={activity.id}>
                                <div className="home-activities__activity-item">
                                    <div
                                        className="home-activities__title"
                                        onClick={() => toggleActivity(activity.id)}
                                    >
                                        <div className="home-activities__icon">{getIcon(activity)}</div>
                                        <div className="home-activities__title-text">
                                            {convertTitle(activity.title).map((part, index) => {
                                                return typeof part === 'string' ? (
                                                    <span
                                                        style={{
                                                            marginRight: '4px',
                                                            marginLeft: index > 0 ? '4px' : 0,
                                                        }}
                                                        key={`${activity.id}-${index}`}
                                                    >
                                                        {part}
                                                    </span>
                                                ) : (
                                                    <span key={`${activity.id}-${index}`}>{part}</span>
                                                );
                                            })}
                                        </div>
                                        <div className="home-activities__expand-icon">
                                            {expandedActivityId === activity.id ? (
                                                <ExpandLessIcon />
                                            ) : (
                                                <ExpandMoreIcon />
                                            )}
                                        </div>
                                    </div>
                                    <Collapse
                                        in={expandedActivityId === activity.id}
                                        timeout="auto"
                                        unmountOnExit
                                        aria-label="Actividades de equipo"
                                        style={{ width: '100%' }}
                                    >
                                        <div className="home-activities__activity-content">
                                            <div style={{ paddingLeft: '40px', fontSize: '14px' }}>
                                                {getActivityContent(activity.content)}
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                {activity?.isClickable && (
                                                    <Typography
                                                        style={{ fontSize: '12px' }}
                                                        className="home-activities__link"
                                                    >
                                                        <Link href={activity.url}>Ver más</Link>
                                                    </Typography>
                                                )}
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        display: 'flex',
                                                        gap: '4px',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <CustomImageComponent
                                                        src={getAuthorLogo(activity.author)}
                                                        alt="autor"
                                                        width={25}
                                                        height={25}
                                                    />
                                                    {getTimeAgo(activity.created)}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </LazyLoadWrapper>
                        );
                    })}
                </React.Fragment>
            );
        });
    };

    return (
        <div className="home-activities">
            {showHeader && (
                <h2 className="home-activities__title">
                    Últimos eventos de Fantasy Fútbol: jugadores lesionados, sancionados y apercibidos
                </h2>
            )}
            {showFilters && (
                <Box display={'flex'} gap={'16px'}>
                    <TextField
                        label="Título"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={searchKeyword}
                        onChange={handleSearchKeywordChange}
                    />
                    <FormControl fullWidth variant="standard" margin="normal">
                        <InputLabel id="activity-type-label">Tipo de Actividad</InputLabel>
                        <Select
                            labelId="activity-type-label"
                            value={selectedType}
                            onChange={handleTypeChange}
                            label="Tipo de Actividad"
                        >
                            <MenuItem value="ALL">Todas</MenuItem>
                            {activityTypes
                                .filter((x) => !x.exclude)
                                .map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {getIcon({ type: type.value })}
                                        <span style={{ marginLeft: '4px' }}>{type.label}</span>
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="standard" margin="normal">
                        <InputLabel id="team-filter-label">Equipo</InputLabel>
                        <Select
                            labelId="team-filter-label"
                            value={selectedTeam}
                            onChange={handleTeamChange}
                            label="Equipo"
                        >
                            <MenuItem value="ALL">Todos los equipos</MenuItem>
                            {obtenerEquipos(LEAGUES.LA_LIGA).map((team) => (
                                <MenuItem key={team.id} value={team.id}>
                                    {team.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}

            <div style={{ ...style, overflowY: 'auto' }}>
                <Box>{getFilteredActivities()}</Box>

                <Box mt={2}>
                    {isPage ? (
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                const skip = Number(actividades?.length);

                                if (loadMoreActividades) {
                                    loadMoreActividades(skip, 100);
                                }
                            }}
                        >
                            Ver más actividades
                        </Button>
                    ) : !isPage ? (
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => router.push(Rutas.ACTIVIDADES)}
                        >
                            Ver todas las actividades
                        </Button>
                    ) : null}
                </Box>
            </div>
        </div>
    );
};

export default HomeActivities;
